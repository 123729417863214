<template>
  <div class="section">
    <div v-if="!whiteLabel" class="container">
      <div class="section-title-wide no-bottom-space">
        <h6 class="subheading text-primary-1">Welcome to The Agent Nest!</h6>

        <h1>Choose Your Plan</h1>
        <h4>
          Your card will NOT be charged at this time. It will be charged once
          your 7 day free trial has finished.
        </h4>
        <img width="200" src="images/agentnestlogonew.png" />
        <div id="servicebot-subscription-portal"></div>
        <!-- <img src="images/molly.gif" /> -->
      </div>
    </div>

    <!--White Label Branding-->
    <div v-if="whiteLabel" class="container">
      <div class="section-title-wide no-bottom-space">
        <h2 class="">Welcome to {{ name }}!</h2>

        <a class="button w-inline-block" href="/">Enter</a>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  data: () => ({
    loading: false,
    publishableKey: process.env.VUE_APP_STRIPE_KEY,
    items: [
      {
        quantity: 1,
      },
    ],
    successUrl: process.env.VUE_APP_URL + "/membership/welcome",
    cancelUrl: process.env.VUE_APP_URL + "/membership/checkout",
    email: null,
    serviceBotId: process.env.VUE_APP_SERVICEBOT_ID,
    hmac: null,
    name: process.env.VUE_APP_COMPANY_NAME,
  }),
  computed: {
    whiteLabel: function () {
      if (process.env.VUE_APP_WHITELABEL == "false") return false;
      return true;
    },
  },
  created() {
    if (this.$route.query.email) {
      this.email = this.$route.query.email;
      this.hmac = this.$route.query.hmac;
    } else {
      this.email = this.$auth.user.email;
      this.hmac = this.$auth.user["https://theagentnest.com/hmac"];
    }

    window.servicebotSettings = {
      servicebot_id: this.serviceBotId,
      options: {
        behavior: {
          signup: {
            showAsPage: true,
            allowChooseAnotherPlan: true,
            setCustomerNameFromCard: true,
          },
        },
        card: {
          hideCountryPostal: true,
          hideAddress: true,
        },
        // "hideTiers": [
        //     "standard"
        // ],
        i18n: {
          defaultLng: "en",
          resources: [
            {
              lng: "en",
              ns: "custom",
              resource: {
                Button_Confirm: "Start Free 7 Day Trial!",
                Button_SelectPlan: "Choose Me!",
              },
            },
          ],
          backend: {},
        },
        forceCard: true,
      },
      handleResponse: function (payload) {
        console.log("Payload monitoring");
        console.log(payload.event);

        if (payload.event == "create_subscription") {
          console.log("ROUTING HERE!!!!!!");
          console.log(payload.response.plan);
          console.log("Service Bot Prodct: " + payload.response.plan.product);
          console.log(
            "Enviroment product: " + process.env.VUE_APP_STRIPE_PLAN_SCHEDULED
          );
          if (
            payload.response.plan.product ==
            process.env.VUE_APP_STRIPE_PLAN_SCHEDULED
          ) {
            window.location.href = "/scheduled/getting-started";
          } else {
            window.location.href = "/membership/welcome";
          }
        }
      },
      service: "Agent Nest Portal",
      interval: "month",
      type: "signup",
      integration: "html",
      email: this.email,
      hash: this.hmac,
    };
    (function () {
      var s = document.createElement("script");
      s.src =
        "https://js.servicebot.io/embeds/servicebot-billing-settings-embed.js";
      s.async = true;
      s.type = "text/javascript";
      //var x = document.getElementsByTagName("script")[0];
      //x.parentNode.insertBefore(s, x);
      document.head.appendChild(s);
    })();
  },
  methods: {
    checkout() {
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
};
</script> 
<style>
.free-trial {
  width: 50%;
  margin-top: 40px;
}
.servicebot-pricing-table.__column-count-more-than-3 .__tier {
  width: calc(100% / 4 + 20px) !important;
}
/* .servicebot-currency:before {
  content: "$0 due today! ";
}
.__description:after {
  content: " after ";
} */
/* .__price {
  color: white;
}
.__signup {
  color: white !important;
}
.__name {
  color: white !important;
} */
</style>